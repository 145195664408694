import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Card, Button, Alert, Spinner } from "react-bootstrap";
import { confirmRegistration } from "../core/_requests";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

export const ConfirmRegistration = () => {
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = params.get("token");

    const confirm = async () => {
      try {
        setLoading(true);
        await confirmRegistration(token as any);
      } catch (err) {
        setError("Ошибка подтверждения регистрации");
      } finally {
        setLoading(false);
      }
    };

    if (token) {
      confirm();
    } else {
      navigate("/auth");
    }
  }, [navigate, params]);

  return (
    <Card className="p-10">
      <div style={{ textAlign: "center", margin: "0 15px 34px 15px" }}>
        <div style={{ marginBottom: "15px" }}>
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/email/icon-positive-vote-1.svg")}
          />
        </div>

        <div
          style={{
            fontSize: "14px",
            fontWeight: "500",
            marginBottom: "27px",
            fontFamily: "Arial,Helvetica,sans-serif",
          }}
        >
          {error ? (
            <Alert variant="danger">{error}</Alert>
          ) : (
            <p
              style={{
                marginBottom: "9px",
                color: "#181C32",
                fontSize: "22px",
                fontWeight: "700",
              }}
            >
              Поздравляем с регистрацией!
            </p>
          )}
        </div>

        <button
          onClick={() => navigate("/auth")}
          style={{
            backgroundColor: "#50cd89",
            borderRadius: "6px",
            display: "inline-block",
            padding: "11px 19px",
            color: "#FFFFFF",
            borderColor: "#fff",
            fontSize: "14px",
            fontWeight: "500",
          }}
        >
          Продолжить
        </button>
      </div>
    </Card>
  );
};
