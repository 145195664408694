import { FC, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const PrivateRoutes = () => {
  const OffersPage = lazy(
    () => import("../modules/apps/offers-management/OffersPage")
  );

  const RequisitesPage = lazy(
    () => import("../modules/apps/requisites-management/RequisitesPage")
  );

  const RequestsPage = lazy(
    () => import("../modules/apps/requests-management/RequestsPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route
          path="auth/*"
          element={<Navigate to="/apps/offer-management/offers" />}
        />
        {/* Pages */}

        <Route
          path="/"
          element={<Navigate to="/apps/offer-management/offers" />}
        />

        <Route
          path="apps/offer-management/*"
          element={
            <SuspensedView>
              <OffersPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/requisites-management/*"
          element={
            <SuspensedView>
              <RequisitesPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/requests-management/*"
          element={
            <SuspensedView>
              <RequestsPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
