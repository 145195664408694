import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { updatePassword } from "../core/_requests";
import { useAuth } from "../core/Auth";
import { removeAllSpaces } from "../../../../_metronic/helpers";

// Регулярное выражение
const passwordRegex = /^[A-Za-z0-9!@#$%^&*()_+\-={}[\]:;"'<>,.?]+$/;

const initialValues = {
  password: "",
  changepassword: "",
};

export function ConfirmPassword() {
  const intl = useIntl();
  const { saveAuth } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const forgotPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(
        8,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 8 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .matches(passwordRegex, "Пароль не должен содержать спецсимволы")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    changepassword: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .matches(passwordRegex, "Пароль не должен содержать спецсимволы")
      .oneOf(
        [Yup.ref("password")],
        intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_MISMATCH" })
      ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const token = searchParams.get("token");
      try {
        if (token) {
          await updatePassword(token, values.password);

          navigate("/auth/login");
        }

        // saveAuth(auth);
        // const { data: user } = await getUserByToken();
        // setCurrentUser(user);
      } catch (error: any) {
        console.error(error);
        saveAuth(undefined);
        const statusText =
          error?.response?.data?.detail?.[0]?.msg ??
          intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_CREDITINALS" });
        setStatus(statusText);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  useEffect(() => {
    if (formik?.values?.password) {
      formik.values.password = removeAllSpaces(formik?.values?.password);
    }
  }, [formik?.values?.password]);

  useEffect(() => {
    if (formik?.values?.changepassword) {
      formik.values.changepassword = removeAllSpaces(
        formik?.values?.changepassword
      );
    }
  }, [formik?.values?.changepassword]);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_password_reset_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">
          {intl.formatMessage({ id: "AUTH.CONFIRM.CONFIRM_TITLE" })}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          {intl.formatMessage({ id: "AUTH.CONFIRM.CONFIRM_DESCR" })}
        </div>
        {/* end::Link */}
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* end::Title */}
      <div className="fv-row mb-8" data-kt-password-meter="true">
        <div className="mb-1">
          <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.PASSWORD.TITLE" })}
          </label>
          <div className="position-relative mb-3">
            <input
              type="password"
              placeholder={intl.formatMessage({
                id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
              })}
              autoComplete="off"
              {...formik.getFieldProps("password")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.password && formik.errors.password,
                },
                {
                  "is-valid":
                    formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className="d-flex align-items-center mb-3"
            data-kt-password-meter-control="highlight"
          >
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className="text-muted">
          {intl.formatMessage(
            { id: "AUTH.VALIDATION.MIN_LENGTH_TITLE" },
            { length: 8 }
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className="fv-row mb-5">
        <label className="form-label fw-bolder text-dark fs-6">
          {intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
        </label>
        <input
          type="password"
          placeholder={intl.formatMessage({
            id: "AUTH.INPUT.CONFIRM_PASSWORD",
          })}
          autoComplete="off"
          {...formik.getFieldProps("changepassword")}
          className={clsx(
            "form-control bg-transparent",
            {
              "is-invalid":
                formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              "is-valid":
                formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-primary me-5 mt-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className="indicator-label">
              {intl.formatMessage({
                id: "AUTH.RESET.EMAIL_CONFIRM",
              })}
            </span>
          )}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              {intl.formatMessage({
                id: "GLOBAL.WAIT",
              })}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link reloadDocument to="/auth/login">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-light-primary me-5 mt-5"
          >
            {intl.formatMessage({
              id: "AUTH.GENERAL.BACK_BUTTON",
            })}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
