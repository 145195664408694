/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  return (
    <>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Меню
          </span>
        </div>
      </div>

      <SidebarMenuItem
        to="/apps/offer-management/offers"
        icon="setting"
        title="Мои предложения"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/apps/requisites-management/requisites"
        icon="two-credit-cart"
        title="Мои реквизиты"
        fontIcon="bi-layers"
      />

      <SidebarMenuItem
        to="/apps/requests-management/requests"
        icon="switch"
        title="Заявки"
        fontIcon="bi-layers"
      />
    </>
  );
};

export { SidebarMenuMain };
