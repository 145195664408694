import axios, { AxiosResponse } from "axios";
import { AuthModel, ReceiverMethodModel } from "..";
import { RefreshToken, TokenFrom2FA } from "../../../../api/core-contract";

const API_URL = process.env.REACT_APP_API_URL;

export const REGISTER_URL = `${API_URL}/signup`;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/`;

export const LOGIN_URL = `${API_URL}/login/token`;

export const REFRESH_URL = `${API_URL}/login/refresh`;

export const LOGOUT = `${API_URL}/logout/`;

export const REQUEST_PASSWORD_URL = `${API_URL}/auth/reset-password`;

export const RESET_PASSWORD = `${API_URL}/auth/update-password`;

export const REGISTRATION_CONFIRM = `${API_URL}/auth/registration`;

export const UPDATE_PASSWORD_CONFIRM = `${API_URL}/auth/update-password`;

export const FREEWIN_CONFIRM_WITHDRAW = `${API_URL}/freewin/confirm/withdraw`;

const GET_ALL_RECEIVER_METHOD = `${API_URL}/operator/receiver_method/all`;

export async function login(
  params: URLSearchParams
  // token: string
): Promise<AuthModel> {
  // const headers = {
  //   "g-recaptcha-response": token,
  // };
  const response = await axios.post<AuthModel>(LOGIN_URL, params);
  return response.data;
}

export async function logoutFromServer(
  refreshToken: RefreshToken
): Promise<void> {
  await axios.delete(LOGOUT, { data: refreshToken });
}

export async function register(
  nickname: string,
  email: string,
  password: string,
  registration_code?: string,
  chat_id?: string
): Promise<AuthModel> {
  const response = await axios.post<AuthModel>(REGISTER_URL, {
    nickname,
    email,
    password,
    registration_code,
    chat_id,
  });
  return response.data;
}

export async function refreshToken(
  refreshToken: RefreshToken
): Promise<AuthModel> {
  const response = await axios.post<AuthModel>(REFRESH_URL, {
    refresh_token: refreshToken,
  });
  return response.data;
}

export async function updatePassword(
  token: string,
  password: string
): Promise<void> {
  await axios.post(
    UPDATE_PASSWORD_CONFIRM,
    { password },
    {
      params: { token },
    }
  );
}

export async function resetPassword(
  email: string
): Promise<{ result: boolean }> {
  const params = new URLSearchParams();
  params.append("email", email);

  const response = await axios.post<{ result: boolean }>(
    REQUEST_PASSWORD_URL,
    {},
    { params }
  );
  return response.data;
}

export async function getReceiverMethodByToken(): Promise<ReceiverMethodModel> {
  const response = await axios.get<ReceiverMethodModel>(
    GET_ALL_RECEIVER_METHOD
  );
  return response.data;
}

export async function confirmRegistration(token: TokenFrom2FA): Promise<void> {
  await axios.post(REGISTRATION_CONFIRM, { token });
}

export async function confirmWithdrawal(token: TokenFrom2FA): Promise<void> {
  const response = await axios.post(FREEWIN_CONFIRM_WITHDRAW, { token });
  return response.data;
}
